<!--高德地图组件-->
<template>
  <div>
    <div style="margin-top: 10px;margin-bottom: 10px;">
      <el-row :gutter="15">
        <el-col :span="14" style="position: relative;">
          <el-input
              @focus="focus"
              @blur="blur"
              @input="search"
              clearable
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="address">
          </el-input>
          <div v-if="showSelect == true" style="color: #606266;border: 1px solid #e4e7ed;box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);background: #fff;border-radius: 4px;margin: 5px 0;position: absolute;z-index: 9999;width: 97%;">
            <div>
              <div class="selectactive" style="margin: 5px;" v-for="(item,index) in seachList" :key="index">
                <div @click.stop="selectaddress(item,index)">{{item.name}}</div>
              </div>
            </div>
            <div v-if="seachList.length == 0">
              <span>暂无数据</span>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <el-button type="info" @click="search">搜索</el-button>
          <el-button type="primary" @click="queding">确定</el-button>
        </el-col>
      </el-row>
      <div id="panel" style="display: none;"></div>
    </div>

    <div id="container1" style="height: 600px;"></div>

    <div style="margin-top: 20px; text-align: center;">
      <!--<el-button type="primary" @click="queding">确定</el-button>-->
    </div>

  </div>
</template>

<script>
// 引入高德地图
import AMapLoader from '@amap/amap-jsapi-loader';
//获取高德地图的key
import setting from '@/config/config'

export default {
  props:{
    data:Number,
    mark: Array,
  },
  data(){
    return{
      //此处不声明 map 对象，可以直接使用 this.map赋值或者采用非响应式的普通对象来存储。
      map:null,

      // 最终选择的经纬度
      location: {},

      // 底部附近数据展示
      list:[],
      // 输入框的值
      address:'',
      // 输入下拉数据
      seachList:[],
      // 输入框选择的数据
      selectdata:{},
      // 是否显示下拉
      showSelect:false,

    }
  },

  mounted() {
    // 高德地图
    window._AMapSecurityConfig = {
      securityJsCode: setting.secretkey,
    }

    console.log(this.data)
    console.log(123456)

    console.log(this.mark,'地址坐标')

    // 重新加载地图
    this.initMap();
  },

  methods:{
    //确定
    queding(){
      this.$emit('location',this.selectdata);
    },

    // 搜索
    search(){
      if(this.address == ''){
        this.$message.error('请输入内容,后再搜索');
      }else {
        // 重新加载地图
        this.initMap();
        this.showSelect = true;
      }
    },

    //获取焦点
    focus(){
      this.showSelect = true;
    },
    //失去焦点
    blur(){
      if(this.address == ''){
        this.showSelect = false;
      }
    },
    //选择数据
    selectaddress(item,index){
      console.log(item)
      // 输入框显示选择的地址
      this.address = item.name;
      // 当前选择的数据
      this.selectdata = item;
      // 传递的数据
      // this.location = item;
      // 隐藏输入提示
      this.showSelect = false;
      // 清除地图覆盖物
      this.map.clearMap();
      // 销毁地图
      // this.map.destroy();
      // 重新加载地图
      this.initMap();
      // 把选择的数据传递给父组件
      // this.$emit('location',this.selectdata);
    },

    // 初始化高德地图
    initMap(){
      AMapLoader.load({
        key: setting.key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins:['AMap.PlaceSearch','AMap.GeometryUtil','AMap.AutoComplete','AMap.Geocoder'],// 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("container1", {
          // 设置地图容器id
          mapStyle: "amap://styles/whitesmoke",
          zoom: 13,
          center: [116.397428, 39.90923],//地图中心点
          resizeEnable: true,
          autoFitView:true,
        });
        let that = this;
        // AMap.plugin('AMap.Geolocation', function() {
        //   var geolocation = new AMap.Geolocation({
        //     enableHighAccuracy: true,//是否使用高精度定位，默认:true
        //     timeout: 10000,          //超过10秒后停止定位，默认：5s
        //     position:'RB',    //定位按钮的停靠位置
        //     offset: [10, 20], //定位按钮与设置的停靠位置的偏移量，默认：[10, 20]
        //     zoomToAccuracy: true,   //定位成功后是否自动调整地图视野到定位点
        //   });
        //   // 获取定位
        //   geolocation.getCurrentPosition(function(status,result){
        //     if(status=='complete'){
        //       // 没有选择地址的时候用来展示
        //       if(Object.keys(that.selectdata).length == 0){
        //         // 定位点
        //         // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
        //         var marker = new AMap.Marker({
        //           map: this.map,
        //           icon: new AMap.Icon({
        //             image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
        //             imageSize: new AMap.Size(30, 41),
        //           }),
        //           position: [result.position.lng, result.position.lat],
        //           offset: new AMap.Pixel(-13, -30),
        //           // 设置是否可以拖拽
        //           // draggable: true,
        //           // cursor: 'move',
        //         });
        //         // 点标记 点击事件
        //         marker.on('dragend', (e) => {
        //           // console.log('最新坐标：',marker.getPosition());
        //           // that.selectdata.location.lng = marker.getPosition().lng;
        //           // that.selectdata.location.lat = marker.getPosition().lat;
        //           // // 把选择的数据传递给父组件
        //           // that.$emit('location',that.selectdata);
        //         });
        //         // 添加点标记
        //         marker.setMap(that.map);
        //         var center = that.map.getCenter();
        //         // var setFitViewBtn = document.getElementById('setFitView');
        //         that.map.setFitView(null, false, [150, 60, 100, 60]);
        //         var newCenter = that.map.getCenter();
        //       }
        //
        //       // 获取输入下拉数据
        //       if(that.address != ''){
        //         AMap.plugin(["AMap.PlaceSearch"], function() {
        //           //构造地点查询类
        //           var placeSearch = new AMap.PlaceSearch({
        //             pageSize: 10, // 单页显示结果条数
        //             pageIndex: 1, // 页码
        //             // city: "010", // 兴趣点城市
        //             // citylimit: true,  //是否强制限制在设置的城市内搜索
        //             //map: that.map, // 展现结果的地图实例
        //             panel: "panel", // 结果列表将在此容器中进行展示。
        //             autoFitView: false // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
        //           });
        //           //关键字查询
        //           placeSearch.search(that.address,function(status, result) {
        //             that.seachList = result.poiList.pois;
        //           });
        //         });
        //       }
        //
        //       // 输入框下拉选择数据后
        //       if(Object.keys(that.selectdata).length != 0){
        //         var marker = new AMap.Marker({
        //           map: that.map,
        //           icon: new AMap.Icon({
        //             image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
        //             imageSize: new AMap.Size(30, 41),
        //           }),
        //           position: [that.selectdata.location.lng,that.selectdata.location.lat],
        //           offset: new AMap.Pixel(-13, -30),
        //           // 设置是否可以拖拽
        //           draggable: true,
        //           cursor: 'move',
        //         });
        //         // 点标记 点击事件
        //         marker.on('dragend', (e) => {
        //           console.log('最新坐标：',marker.getPosition());
        //           that.selectdata.location.lng = marker.getPosition().lng;
        //           that.selectdata.location.lat = marker.getPosition().lat;
        //           // 把选择的数据传递给父组件
        //           that.$emit('location',that.selectdata);
        //         });
        //         // 添加点标记
        //         marker.setMap(that.map);
        //         var center = that.map.getCenter();
        //         // var setFitViewBtn = document.getElementById('setFitView');
        //         // that.map.setFitView(null, false, [150, 60, 100, 60]);
        //         that.map.setFitView();
        //         var newCenter = that.map.getCenter();
        //       }
        //     }else{
        //       // onError(result)
        //       console.log(result);
        //     }
        //   });
        // });

        // 没有选择地址的时候用来展示
        if(Object.keys(that.selectdata).length == 0){
          console.log('1')
          // 定位点
          // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
          var marker = new AMap.Marker({
            map: this.map,
            icon: new AMap.Icon({
              image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
              imageSize: new AMap.Size(30, 41),
            }),
            position: [116.397428, 39.90923],
            offset: new AMap.Pixel(-13, -30),
            // 设置是否可以拖拽
            draggable: true,
            cursor: 'move',
          });
          // 点标记 点击事件
          marker.on('dragend', (e) => {
            var that = this;
            console.log('最新坐标：',marker.getPosition());
            //反查地址-S
            let geocoder
            AMap.plugin('AMap.Geocoder',function(){
              geocoder = new AMap.Geocoder()
            })
            geocoder.getAddress([marker.getPosition().lng,marker.getPosition().lat],function (status, result) {
              if (status === "complete" && result.info === "OK") {
                console.log('地址获取成功',result)
                console.log('地址获取成功：',result.regeocode.formattedAddress)
                that.selectdata = {};
                that.selectdata = {
                  pname:result.regeocode.addressComponent.province,
                  cityname: result.regeocode.addressComponent.city,
                  adname: result.regeocode.addressComponent.district,
                  address: result.regeocode.formattedAddress,
                  location: {
                    lng: marker.getPosition().lng,
                    lat: marker.getPosition().lat,
                  }
                }
                // that.$emit('location',that.selectdata);
              }
            });
            //反查地址-
          });
          // 添加点标记
          marker.setMap(that.map);
          var center = that.map.getCenter();
          // var setFitViewBtn = document.getElementById('setFitView');
          that.map.setFitView(null, false, [150, 60, 100, 60]);
          var newCenter = that.map.getCenter();
        }

        // 如果 mark 不登录空 或者不等于undefined
        if(that.mark!= undefined){
          var marker = new AMap.Marker({
            map: this.map,
            icon: new AMap.Icon({
              image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
              imageSize: new AMap.Size(30, 41),
            }),
            position: [that.mark[0], that.mark[1]],
            offset: new AMap.Pixel(-13, -30),
            // 设置是否可以拖拽
            draggable: true,
            cursor: 'move',
          });
          // 点标记 点击事件
          marker.on('dragend', (e) => {
            var that = this;
            console.log('最新坐标：',marker.getPosition());
            //反查地址-S
            let geocoder
            AMap.plugin('AMap.Geocoder',function(){
              geocoder = new AMap.Geocoder()
            })
            geocoder.getAddress([marker.getPosition().lng,marker.getPosition().lat],function (status, result) {
              if (status === "complete" && result.info === "OK") {
                console.log('地址获取成功',result)
                console.log('地址获取成功：',result.regeocode.formattedAddress)
                that.selectdata = {};
                that.selectdata = {
                  pname:result.regeocode.addressComponent.province,
                  cityname: result.regeocode.addressComponent.city,
                  adname: result.regeocode.addressComponent.district,
                  address: result.regeocode.formattedAddress,
                  location: {
                    lng: marker.getPosition().lng,
                    lat: marker.getPosition().lat,
                  }
                }
                // that.$emit('location',that.selectdata);
              }
            });
            //反查地址-
          });
          // 添加点标记
          marker.setMap(that.map);
          var center = that.map.getCenter();
          // var setFitViewBtn = document.getElementById('setFitView');
          that.map.setFitView(null, false, [150, 60, 100, 60]);
          var newCenter = that.map.getCenter();
        }

        // 获取输入下拉数据
        if(that.address != ''){
          console.log('2')
          AMap.plugin(["AMap.PlaceSearch"], function() {
            //构造地点查询类
            var placeSearch = new AMap.PlaceSearch({
              pageSize: 10, // 单页显示结果条数
              pageIndex: 1, // 页码
              // city: "010", // 兴趣点城市
              // citylimit: true,  //是否强制限制在设置的城市内搜索
              //map: that.map, // 展现结果的地图实例
              panel: "panel", // 结果列表将在此容器中进行展示。
              autoFitView: false // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
            });
            //关键字查询
            placeSearch.search(that.address,function(status, result) {
              console.log('3')
              that.seachList = result.poiList.pois;
            });
          });
        }

        // 输入框下拉选择数据后
        if(Object.keys(that.selectdata).length != 0){
          var marker = new AMap.Marker({
            map: that.map,
            icon: new AMap.Icon({
              image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
              imageSize: new AMap.Size(30, 41),
            }),
            position: [that.selectdata.location.lng,that.selectdata.location.lat],
            offset: new AMap.Pixel(-13, -30),
            // 设置是否可以拖拽
            draggable: true,
            cursor: 'move',
          });
          // 点标记 点击事件
          marker.on('dragend', (e) => {
            var that = this;
            console.log('最新坐标：',marker.getPosition());
            //反查地址-S
            let geocoder
            AMap.plugin('AMap.Geocoder',function(){
              geocoder = new AMap.Geocoder()
            })
            geocoder.getAddress([marker.getPosition().lng,marker.getPosition().lat],function (status, result) {
              if (status === "complete" && result.info === "OK") {
                console.log('地址获取成功',result)
                console.log('地址获取成功：',result.regeocode.formattedAddress)
                that.selectdata = {};
                that.selectdata = {
                  pname:result.regeocode.addressComponent.province,
                  cityname: result.regeocode.addressComponent.city,
                  adname: result.regeocode.addressComponent.district,
                  address: result.regeocode.formattedAddress,
                  location: {
                    lng: marker.getPosition().lng,
                    lat: marker.getPosition().lat,
                  }
                }
                // that.$emit('location',that.selectdata);
              }
            });
            //反查地址-
          });
          // 添加点标记
          marker.setMap(that.map);
          var center = that.map.getCenter();
          // var setFitViewBtn = document.getElementById('setFitView');
          // that.map.setFitView(null, false, [150, 60, 100, 60]);
          that.map.setFitView();
          var newCenter = that.map.getCenter();
        }

      }).catch((e) => {
        console.log(e);
      });

    },



  }

}
</script>

<style lang="scss" scoped>
#container{
  padding:0px;
  margin: 0px;
  width: 100%;
  //min-height: 300px;
  //max-height: 750px;
}
.selectactive{
  padding: 5px;
}
.selectactive:hover{
  background: #f5f7fa;
  padding: 5px;
  cursor: pointer;
}
</style>
